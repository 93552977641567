import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login, register } from "../utils/api";
import toast, { Toaster } from "react-hot-toast";
import { FaUser, FaLock, FaEnvelope, FaKey } from "react-icons/fa";
import PasswordStrengthIndicator from "../components/PasswordStrengthIndicator";

const Auth = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/"); // Redireciona para a página principal se já estiver autenticado
    }
  }, [navigate]);

  const handleAuth = async (e) => {
    e.preventDefault(); // Previne o comportamento padrão de submissão do formulário

    setError(""); // Resetar erro ao tentar novamente
    setPasswordError(false); // Resetar erro da senha

    // Validar campos
    const trimmedUsername = username.trim().toLowerCase();
    const trimmedName = name.trim();
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();
    const trimmedConfirmPassword = confirmPassword.trim();

    // Validar caracteres especiais no username e comprimento mínimo apenas no registro
    if (!isLogin) {
      const usernameRegex = /^[a-zA-Z0-9]{6,}$/;
      if (!usernameRegex.test(trimmedUsername)) {
        setError(
          "O nome de usuário deve ter pelo menos 6 caracteres e conter apenas letras e números."
        );
        toast.error(
          "O nome de usuário deve ter pelo menos 6 caracteres e conter apenas letras e números."
        );
        return;
      }

      // Validar nome completo (nome e sobrenome)
      const nameParts = trimmedName.split(" ");
      if (nameParts.length < 2) {
        setError("Por favor, insira seu nome completo (nome e sobrenome).");
        toast.error("Por favor, insira seu nome completo (nome e sobrenome).");
        return;
      }

      // Validar senha
      const passwordRegex =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      if (!passwordRegex.test(trimmedPassword)) {
        setPasswordError(true);
        toast.error(
          "A senha deve ter pelo menos 8 caracteres, incluindo 1 número e 1 caractere especial."
        );
        return;
      }

      if (trimmedPassword !== trimmedConfirmPassword) {
        setError("As senhas não coincidem.");
        toast.error("As senhas não coincidem.");
        return;
      }

      // Prevenir que o usuário use a senha de exemplo
      if (trimmedPassword === "Exemplo@123") {
        setError("A senha não pode ser a senha de exemplo.");
        toast.error("A senha não pode ser a senha de exemplo.");
        return;
      }
    }

    try {
      if (isLogin) {
        const response = await login(trimmedUsername, trimmedPassword);
        if (response && response.data) {
          toast.success("Login realizado com sucesso!");
          navigate("/"); // Redireciona para a página principal após login bem-sucedido
        } else {
          const errorMessage = response.message || "Erro ao realizar o login.";
          setError(errorMessage);
          toast.error(errorMessage);
        }
      } else {
        const response = await register(
          trimmedName,
          trimmedEmail,
          trimmedUsername,
          trimmedPassword
        );
        if (response && response.success) {
          toast.success("Conta criada com sucesso! Por favor, faça login.");
          setIsLogin(true); // Altera para o formulário de login após registro bem-sucedido
        } else {
          const errorMessage =
            response.message || "Erro ao realizar o registro.";
          setError(errorMessage);
          toast.error(errorMessage);
        }
      }
    } catch (err) {
      console.error("Erro de autenticação:", err);
      const errorMessage =
        err.response?.data?.message ||
        "Erro ao realizar a operação. Verifique suas credenciais ou tente novamente mais tarde.";
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="max-w-md w-full space-y-8 bg-gray-800 p-8 rounded-lg shadow-lg">
        <div className="text-center">
          <h2 className="text-4xl font-bold text-white">KaizenADS</h2>
        </div>
        <h1 className="text-2xl font-bold text-center text-white">
          {isLogin ? "Login" : "Registro"}
        </h1>
        <form onSubmit={handleAuth}>
          <div>
            {!isLogin && (
              <>
                {/* Nome Completo */}
                <div className="mb-4 flex items-center">
                  <FaUser className="text-gray-400 mr-2" />
                  <label htmlFor="name" className="block text-gray-300 w-full">
                    <input
                      id="name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="mt-1 block w-full bg-gray-700 border-gray-600 text-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2.5 px-4"
                      placeholder="Nome Completo"
                      required
                    />
                  </label>
                </div>
                {/* Helper Text para Nome Completo */}
                <p className="text-xs text-gray-400 mb-4">
                  Por favor, insira seu nome e sobrenome.
                </p>

                {/* Email */}
                <div className="mb-4 flex items-center">
                  <FaEnvelope className="text-gray-400 mr-2" />
                  <label htmlFor="email" className="block text-gray-300 w-full">
                    <input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full bg-gray-700 border-gray-600 text-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2.5 px-4"
                      placeholder="Email"
                      required
                    />
                  </label>
                </div>
              </>
            )}
            {/* Username */}
            <div className="mb-4 flex items-center">
              <FaUser className="text-gray-400 mr-2" />
              <label htmlFor="username" className="block text-gray-300 w-full">
                <input
                  id="username"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mt-1 block w-full bg-gray-700 border-gray-600 text-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2.5 px-4"
                  placeholder="Username"
                  required
                />
              </label>
            </div>
            {/* Helper Text para Username (Apenas no Registro) */}
            {!isLogin && (
              <p className="text-xs text-gray-400 mb-4">
                O nome de usuário deve ter pelo menos 6 caracteres e conter
                apenas letras e números.
              </p>
            )}

            {/* Password */}
            <div className="mb-4 flex items-center">
              <FaLock className="text-gray-400 mr-2" />
              <label htmlFor="password" className="block text-gray-300 w-full">
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full bg-gray-700 border-gray-600 text-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2.5 px-4"
                  placeholder="Senha"
                  required
                />
              </label>
            </div>

            {/* Indicador de força da senha (Apenas no Registro) */}
            {!isLogin && <PasswordStrengthIndicator password={password} />}

            {/* Confirmar Password (Apenas no Registro) */}
            {!isLogin && (
              <div className="mb-4 flex items-center">
                <FaKey className="text-gray-400 mr-2" />
                <label
                  htmlFor="confirmPassword"
                  className="block text-gray-300 w-full"
                >
                  <input
                    id="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mt-1 block w-full bg-gray-700 border-gray-600 text-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2.5 px-4"
                    placeholder="Confirme a Senha"
                    required
                  />
                </label>
              </div>
            )}

            {/* Botão de Autenticação */}
            <button
              type="submit"
              className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2.5 rounded-md w-full"
            >
              {isLogin ? "Login" : "Registrar"}
            </button>

            {/* Alternar entre Login e Registro */}
            <div className="mt-4 text-center">
              <button
                onClick={() => setIsLogin(!isLogin)}
                className="text-indigo-400 hover:text-indigo-500"
                type="button"
              >
                {isLogin ? "Criar uma conta" : "Já tem uma conta? Faça login"}
              </button>
            </div>
          </div>
        </form>
        <Toaster position="top-right" />
      </div>
    </div>
  );
};

export default Auth;
