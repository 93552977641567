// MenuItems.jsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaBullhorn,
  FaChevronDown,
  FaChevronUp,
  FaPen,
  FaGoogle,
  FaLock,
  FaTools,
  FaUserShield,
  FaUsers,
  FaKey,
  FaTachometerAlt, // Já está importado
  FaClipboardList,
  FaChartBar,
  FaMicrochip,
  FaCalculator, // Certifique-se de importar o ícone
} from "react-icons/fa";

const MenuItems = ({
  activeMenu,
  toggleMenu,
  isMinimized,
  isAdmin,
  hasGadsIntegration,
}) => {
  const location = useLocation();

  return (
    <ul className="mt-4 flex-grow">
      {/* Dashboard */}
      <li
        className={`px-4 py-2 hover:bg-gray-700 flex items-center ${
          location.pathname === "/dashboard" ? "font-bold" : ""
        } ${isMinimized ? "justify-center" : "text-gray-300"}`}
      >
        <FaTachometerAlt className="text-gray-400" />
        {!isMinimized && (
          <Link to="/dashboard" className="ml-2">
            Dashboard
          </Link>
        )}
      </li>

      {/* Campanhas */}
      <li
        className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
          activeMenu === "campaigns" ? "bg-gray-800" : ""
        } ${isMinimized ? "justify-center" : "text-gray-300"}`}
        onClick={() => toggleMenu("campaigns")}
      >
        <div className="flex items-center">
          <FaBullhorn className="text-gray-400" />
          {!isMinimized && <span className="ml-2">Campanhas</span>}
        </div>
        {!isMinimized &&
          (activeMenu === "campaigns" ? (
            <FaChevronUp className="text-gray-400" />
          ) : (
            <FaChevronDown className="text-gray-400" />
          ))}
      </li>
      {activeMenu === "campaigns" && !isMinimized && (
        <ul className="pl-6">
          <li
            className={`px-4 py-2 hover:bg-gray-700 text-gray-300 ${
              location.pathname === "/campaigns" ? "font-bold" : ""
            }`}
          >
            <Link to="/campaigns">Criação de Campanha</Link>
          </li>
          <li
            className={`px-4 py-2 hover:bg-gray-700 text-gray-300 ${
              location.pathname === "/campaigns-history" ? "font-bold" : ""
            }`}
          >
            <Link to="/campaigns-history">Histórico de Campanhas</Link>
          </li>
        </ul>
      )}

      {/* Criação de conteúdo */}
      <li
        className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
          activeMenu === "contentCreation" ? "bg-gray-800" : ""
        } ${isMinimized ? "justify-center" : "text-gray-300"}`}
        onClick={() => toggleMenu("contentCreation")}
      >
        <div className="flex items-center">
          <FaPen className="text-gray-400" />
          {!isMinimized && <span className="ml-2">Criação de conteúdo</span>}
        </div>
        {!isMinimized &&
          (activeMenu === "contentCreation" ? (
            <FaChevronUp className="text-gray-400" />
          ) : (
            <FaChevronDown className="text-gray-400" />
          ))}
      </li>
      {activeMenu === "contentCreation" && !isMinimized && (
        <ul className="pl-6">
          <li
            className={`px-4 py-2 flex items-center hover:bg-gray-700 text-gray-300 ${
              location.pathname === "/tools/keywordschecker" ? "font-bold" : ""
            }`}
          >
            <FaKey
              className={`mr-2 ${
                location.pathname === "/tools/keywordschecker"
                  ? "text-white"
                  : "text-gray-400"
              }`}
            />
            <Link to="/tools/keywordschecker">KeywordChecker</Link>
          </li>
          {/* Outros itens bloqueados */}
          <li className="px-4 py-2 flex items-center text-gray-500 cursor-not-allowed">
            <FaTools className="mr-2" />
            <span>KAuto Article</span>
            <FaLock className="ml-auto text-gray-400" />
          </li>
          <li className="px-4 py-2 flex items-center text-gray-500 cursor-not-allowed">
            <FaTools className="mr-2" />
            <span>KAuto Keyword Planner</span>
            <FaLock className="ml-auto text-gray-400" />
          </li>
          <li className="px-4 py-2 flex items-center text-gray-500 cursor-not-allowed">
            <FaTools className="mr-2" />
            <span>KAuto Policy Detector</span>
            <FaLock className="ml-auto text-gray-400" />
          </li>
        </ul>
      )}

      {/* Google Ads API */}
      {hasGadsIntegration && (
        <>
          <li
            className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
              activeMenu === "googleAdsAPI" ? "bg-gray-800" : ""
            } ${isMinimized ? "justify-center" : "text-gray-300"}`}
            onClick={() => toggleMenu("googleAdsAPI")}
          >
            <div className="flex items-center">
              <FaGoogle className="text-gray-400" />
              {!isMinimized && <span className="ml-2">Google ADS API</span>}
            </div>
            {!isMinimized &&
              (activeMenu === "googleAdsAPI" ? (
                <FaChevronUp className="text-gray-400" />
              ) : (
                <FaChevronDown className="text-gray-400" />
              ))}
          </li>
          {activeMenu === "googleAdsAPI" && !isMinimized && (
            <ul className="pl-6">
              <li
                className={`px-4 py-2 hover:bg-gray-700 text-gray-300 ${
                  location.pathname === "/google-ads-api/projects"
                    ? "font-bold"
                    : ""
                }`}
              >
                <Link to="/google-ads-api/projects">Gerenciar Projetos</Link>
              </li>
            </ul>
          )}
        </>
      )}

      {/* Ferramentas */}
      <li
        className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
          activeMenu === "tools" ? "bg-gray-800" : ""
        } ${isMinimized ? "justify-center" : "text-gray-300"}`}
        onClick={() => toggleMenu("tools")}
      >
        <div className="flex items-center">
          <FaTools className="text-gray-400" />
          {!isMinimized && <span className="ml-2">Ferramentas</span>}
        </div>
        {!isMinimized &&
          (activeMenu === "tools" ? (
            <FaChevronUp className="text-gray-400" />
          ) : (
            <FaChevronDown className="text-gray-400" />
          ))}
      </li>
      {activeMenu === "tools" && !isMinimized && (
        <ul className="pl-6">
          <li
            className={`px-4 py-2 hover:bg-gray-700 text-gray-300 ${
              location.pathname === "/rps-calculation" ? "font-bold" : ""
            }`}
          >
            <Link to="/rps-calculation">Cálculo de RPS</Link>
          </li>
          {/* Adicione outros itens de ferramentas aqui, se necessário */}
        </ul>
      )}

      {/* Admin */}
      {isAdmin && (
        <>
          <li
            className={`px-4 py-2 hover:bg-gray-700 flex items-center justify-between cursor-pointer ${
              activeMenu === "admin" ? "bg-gray-800" : ""
            } ${isMinimized ? "justify-center" : "text-gray-300"}`}
            onClick={() => toggleMenu("admin")}
          >
            <div className="flex items-center">
              <FaUserShield className="text-gray-400" />
              {!isMinimized && <span className="ml-2">Administrador</span>}
            </div>
            {!isMinimized &&
              (activeMenu === "admin" ? (
                <FaChevronUp className="text-gray-400" />
              ) : (
                <FaChevronDown className="text-gray-400" />
              ))}
          </li>
          {activeMenu === "admin" && !isMinimized && (
            <ul className="pl-6">
              <li
                className={`px-4 py-2 hover:bg-gray-700 text-gray-300 flex items-center ${
                  location.pathname === "/admin/users" ? "font-bold" : ""
                }`}
              >
                <FaUsers className="mr-2 text-gray-400" />
                <Link to="/admin/users">Usuários</Link>
              </li>
              <li
                className={`px-4 py-2 hover:bg-gray-700 text-gray-300 flex items-center ${
                  location.pathname === "/system-logs" ? "font-bold" : ""
                }`}
              >
                <FaClipboardList className="mr-2 text-gray-400" />
                <Link to="/system-logs">Logs do Sistema</Link>
              </li>
              <li
                className={`px-4 py-2 hover:bg-gray-700 text-gray-300 flex items-center ${
                  location.pathname === "/sales-report" ? "font-bold" : ""
                }`}
              >
                <FaChartBar className="mr-2 text-gray-400" />
                <Link to="/sales-report">Relatório de Vendas</Link>
              </li>
              <li
                className={`px-4 py-2 hover:bg-gray-700 text-gray-300 flex items-center ${
                  location.pathname === "/workers-monitor" ? "font-bold" : ""
                }`}
              >
                <FaMicrochip className="mr-2 text-gray-400" />
                <Link to="/workers-monitor">Workers Monitor</Link>
              </li>
            </ul>
          )}
        </>
      )}
    </ul>
  );
};

export default MenuItems;
