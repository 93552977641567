import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

const PasswordStrengthIndicator = ({ password }) => {
  const requirements = [
    {
      text: "Pelo menos 8 caracteres",
      validator: (pass) => pass.length >= 8,
    },
    {
      text: "Pelo menos uma letra maiúscula",
      validator: (pass) => /[A-Z]/.test(pass),
    },
    {
      text: "Pelo menos uma letra minúscula",
      validator: (pass) => /[a-z]/.test(pass),
    },
    {
      text: "Pelo menos um número",
      validator: (pass) => /[0-9]/.test(pass),
    },
    {
      text: "Pelo menos um caractere especial",
      validator: (pass) => /[!@#$%^&*]/.test(pass),
    },
  ];

  const calculateStrength = () => {
    // Requisitos básicos atendidos
    const passedRequirements = requirements.filter((req) =>
      req.validator(password)
    ).length;

    // Pontuação base (60% do peso)
    let baseScore = (passedRequirements / requirements.length) * 60;

    // Comprimento da senha (20% do peso)
    const lengthScore = Math.min((password.length - 8) * 2, 20);

    // Variedade de caracteres (20% do peso)
    let varietyScore = 0;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecials = /[!@#$%^&*]/.test(password);
    const uniqueChars = new Set(password).size;

    // Adiciona pontos pela variedade de tipos de caracteres
    if (hasUpperCase) varietyScore += 5;
    if (hasLowerCase) varietyScore += 5;
    if (hasNumbers) varietyScore += 5;
    if (hasSpecials) varietyScore += 5;

    // Bônus para caracteres únicos
    varietyScore += Math.min((uniqueChars / password.length) * 20, 20);
    varietyScore = Math.min(varietyScore, 20);

    // Pontuação total
    return Math.min(baseScore + lengthScore + varietyScore, 100);
  };

  const getStrengthText = () => {
    const strength = calculateStrength();
    if (strength === 0) return "";
    if (strength <= 25) return "Muito fraca";
    if (strength <= 50) return "Fraca";
    if (strength <= 75) return "Média";
    if (strength <= 90) return "Forte";
    return "Muito forte";
  };

  const getStrengthColor = () => {
    const strength = calculateStrength();
    if (strength <= 25) return "bg-red-500";
    if (strength <= 50) return "bg-orange-500";
    if (strength <= 75) return "bg-yellow-500";
    if (strength <= 90) return "bg-blue-500";
    return "bg-green-500";
  };

  return (
    <div className="mt-2 mb-4">
      {/* Barra de progresso */}
      <div className="w-full h-2 bg-gray-700 rounded-full mb-2">
        <div
          className={`h-full rounded-full transition-all duration-300 ${getStrengthColor()}`}
          style={{ width: `${calculateStrength()}%` }}
        ></div>
      </div>

      {/* Texto indicador de força */}
      {password && (
        <p className="text-xs text-gray-400 mb-2">
          Força da senha:{" "}
          <span
            className={`font-semibold ${getStrengthColor().replace(
              "bg-",
              "text-"
            )}`}
          >
            {getStrengthText()}
          </span>
        </p>
      )}

      {/* Lista de requisitos */}
      <div className="space-y-1">
        {requirements.map((requirement, index) => (
          <div key={index} className="flex items-center text-xs">
            {requirement.validator(password) ? (
              <FaCheck className="text-green-500 mr-2" />
            ) : (
              <FaTimes className="text-red-500 mr-2" />
            )}
            <span
              className={`${
                requirement.validator(password)
                  ? "text-green-500"
                  : "text-gray-400"
              }`}
            >
              {requirement.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PasswordStrengthIndicator;
