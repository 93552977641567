import React, { useState, useRef } from "react";
import { modifyAdGroupBid, setCpcMax, setCpcMin } from "../utils/api";
import { toast } from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";

const BATCH_SIZE = 5;

const BulkActionsBar = ({ selectedRows, reports, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newCpcValue, setNewCpcValue] = useState("");
  const [newMaxValue, setNewMaxValue] = useState("");
  const [newMinValue, setNewMinValue] = useState("");
  const [progress, setProgress] = useState(0);
  const [totalOperations, setTotalOperations] = useState(0);
  const [completedOperations, setCompletedOperations] = useState(0);

  // Refs para controlar o progresso
  const completedRef = useRef(0);
  const totalRef = useRef(0);

  const updateProgress = () => {
    completedRef.current += 1;
    const currentProgress = (completedRef.current / totalRef.current) * 100;
    setCompletedOperations(completedRef.current);
    setProgress(currentProgress);
  };

  const getSelectedCampaigns = () => {
    console.log("selectedRows:", selectedRows);
    console.log("reports:", reports);

    const campaigns = Object.entries(selectedRows)
      .filter(([_, isSelected]) => isSelected)
      .map(([adGroupId]) => {
        const campaign = reports.find(
          (report) => String(report.adGroupId) === String(adGroupId)
        );
        console.log("Found campaign:", campaign);
        return campaign;
      })
      .filter(Boolean);

    console.log("Selected campaigns:", campaigns);
    return campaigns;
  };

  const handleBulkCpcUpdate = async () => {
    if (!newCpcValue || isNaN(newCpcValue)) {
      toast.error("Por favor, insira um valor válido para o CPC");
      return;
    }

    try {
      setIsSubmitting(true);
      const selectedCampaigns = getSelectedCampaigns();

      if (selectedCampaigns.length === 0) {
        toast.error("Nenhuma campanha selecionada");
        return;
      }

      // Define o total de operações
      const totalOps = selectedCampaigns.length;
      setTotalOperations(totalOps);
      setCompletedOperations(0);
      setProgress(0);

      const errors = [];

      // Processa as campanhas em lotes de BATCH_SIZE
      for (let i = 0; i < selectedCampaigns.length; i += BATCH_SIZE) {
        const batchCampaigns = selectedCampaigns.slice(i, i + BATCH_SIZE);

        try {
          // Executa as operações do lote em paralelo
          const results = await Promise.all(
            batchCampaigns.map(async (campaign) => {
              try {
                const response = await modifyAdGroupBid({
                  projectId: campaign.projectId,
                  campaignId: campaign.campaignId,
                  adGroupId: campaign.adGroupId,
                  adjustValue: parseFloat(newCpcValue),
                  adjustType:
                    campaign.campaignDetails.bidding_strategy_type === 10
                      ? "target_cpa_micros"
                      : "cpc_bid_micros",
                });

                // Atualiza o progresso após cada operação individual
                setCompletedOperations((prev) => {
                  const newValue = prev + 1;
                  setProgress((newValue / totalOps) * 100);
                  return newValue;
                });

                if (!response.success) {
                  return `${campaign.campaignName}: ${response.error}`;
                }
                return null;
              } catch (error) {
                return `${campaign.campaignName}: ${error.message}`;
              }
            })
          );

          // Filtra e adiciona erros do lote atual
          errors.push(...results.filter(Boolean));
        } catch (error) {
          console.error("Erro ao processar lote:", error);
          errors.push(`Erro ao processar lote: ${error.message}`);
        }
      }

      if (errors.length > 0) {
        toast.error(`Alguns erros ocorreram: ${errors.join(", ")}`);
      } else {
        toast.success(
          "CPC atualizado com sucesso para todas as campanhas selecionadas!"
        );
        onClose();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const processBatch = async (currentBatch) => {
    // Mapeia todas as operações necessárias
    const operations = currentBatch.flatMap((campaign) => {
      const ops = [];
      if (newMaxValue) {
        ops.push({
          type: "max",
          campaign,
          value: parseFloat(newMaxValue),
        });
      }
      if (newMinValue) {
        ops.push({
          type: "min",
          campaign,
          value: parseFloat(newMinValue),
        });
      }
      return ops;
    });

    // Define o total de operações
    const totalOps = operations.length;
    setTotalOperations(totalOps);
    setCompletedOperations(0);
    setProgress(0);

    const errors = [];

    // Processa as operações em lotes de BATCH_SIZE
    for (let i = 0; i < operations.length; i += BATCH_SIZE) {
      const batchOperations = operations.slice(i, i + BATCH_SIZE);

      try {
        // Executa as operações do lote em paralelo
        const results = await Promise.all(
          batchOperations.map(async (op) => {
            try {
              const apiFunction = op.type === "max" ? setCpcMax : setCpcMin;
              const result = await apiFunction(
                op.campaign.campaignId,
                op.campaign.adGroupId,
                {
                  morning: op.value,
                  afternoon: op.value,
                  evening: op.value,
                  dawn: op.value,
                },
                op.campaign.projectId
              );

              // Atualiza o progresso após cada operação individual
              setCompletedOperations((prev) => {
                const newValue = prev + 1;
                setProgress((newValue / totalOps) * 100);
                return newValue;
              });

              if (!result.success) {
                return `${op.type === "max" ? "Max" : "Min"} ${
                  op.campaign.campaignName
                }: ${result.error || result.message}`;
              }
              return null;
            } catch (error) {
              return `${op.type === "max" ? "Max" : "Min"} ${
                op.campaign.campaignName
              }: ${error.message}`;
            }
          })
        );

        // Filtra e adiciona erros do lote atual
        errors.push(...results.filter(Boolean));
      } catch (error) {
        console.error("Erro ao processar lote:", error);
        errors.push(`Erro ao processar lote: ${error.message}`);
      }
    }

    return errors;
  };

  const handleBulkMaxMinUpdate = async () => {
    if (
      (!newMaxValue && !newMinValue) ||
      (newMaxValue && isNaN(newMaxValue)) ||
      (newMinValue && isNaN(newMinValue))
    ) {
      toast.error("Por favor, insira valores válidos");
      return;
    }

    try {
      setIsSubmitting(true);
      setProgress(0);
      setCompletedOperations(0);
      setTotalOperations(0);

      const selectedCampaigns = getSelectedCampaigns();
      if (selectedCampaigns.length === 0) {
        toast.error("Nenhuma campanha selecionada");
        return;
      }

      const errors = await processBatch(selectedCampaigns);

      if (errors.length > 0) {
        toast.error(`Alguns erros ocorreram: ${errors.join(", ")}`);
      } else {
        toast.success("Valores Max/Min atualizados com sucesso!");
        onClose();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t p-3 z-50">
      <div className="max-w-5xl mx-auto flex flex-col gap-2">
        {isSubmitting && (
          <>
            <div className="text-center text-sm text-yellow-600 bg-yellow-50 p-2 rounded">
              <FaSpinner className="inline animate-spin mr-2" />
              Processando alterações. Por favor, aguarde e não feche esta
              tela...
              <div className="text-xs mt-1">
                Progresso: {completedOperations} de {totalOperations} operações
              </div>
            </div>
            <div className="h-1 bg-gray-200 rounded overflow-hidden">
              <div
                className="h-full bg-blue-500 transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
          </>
        )}

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <span className="text-sm text-gray-600">
              {selectedRows &&
                Object.keys(selectedRows).filter((key) => selectedRows[key])
                  .length}{" "}
              campanhas selecionadas
            </span>

            <div className="flex items-center gap-2">
              <input
                type="number"
                step="0.01"
                placeholder="Novo CPC"
                value={newCpcValue}
                onChange={(e) => setNewCpcValue(e.target.value)}
                className="border rounded px-2 py-1 w-20 text-sm"
                disabled={isSubmitting}
              />
              <button
                onClick={handleBulkCpcUpdate}
                disabled={isSubmitting}
                className="bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600 disabled:opacity-50 flex items-center gap-1"
              >
                {isSubmitting && <FaSpinner className="animate-spin" />}
                CPC
              </button>
            </div>

            <div className="flex items-center gap-2">
              <input
                type="number"
                step="0.01"
                placeholder="Min"
                value={newMinValue}
                onChange={(e) => setNewMinValue(e.target.value)}
                className="border rounded px-2 py-1 w-20 text-sm"
                disabled={isSubmitting}
              />
              <input
                type="number"
                step="0.01"
                placeholder="Max"
                value={newMaxValue}
                onChange={(e) => setNewMaxValue(e.target.value)}
                className="border rounded px-2 py-1 w-20 text-sm"
                disabled={isSubmitting}
              />
              <button
                onClick={handleBulkMaxMinUpdate}
                disabled={isSubmitting}
                className="bg-green-500 text-white px-3 py-1 rounded text-sm hover:bg-green-600 disabled:opacity-50 flex items-center gap-1"
              >
                {isSubmitting && <FaSpinner className="animate-spin" />}
                Max/Min
              </button>
            </div>
          </div>

          {!isSubmitting && (
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 text-sm"
            >
              Cancelar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkActionsBar;
