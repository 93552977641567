import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FaCalculator, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { getExchangeRate } from "../utils/api";

const RPSCalculation = () => {
  const [sessions, setSessions] = useState("");
  const [impressions, setImpressions] = useState("");
  const [ecpm, setEcpm] = useState("");
  const [exchangeRate, setExchangeRate] = useState(5.81); // Valor padrão
  const [roiGoal, setRoiGoal] = useState(0.5);

  const [results, setResults] = useState(null);
  const [calculatedROI, setCalculatedROI] = useState(null);

  useEffect(() => {
    // Buscar a cotação do dólar em tempo real ao montar o componente
    const fetchExchangeRate = async () => {
      try {
        const rate = await getExchangeRate();
        if (rate) {
          setExchangeRate(rate);
        }
      } catch (error) {
        toast.error("Erro ao buscar a cotação do dólar.");
      }
    };

    fetchExchangeRate();
  }, []);

  const handleCalculate = (e) => {
    e.preventDefault();

    // Validação dos inputs
    if (!sessions || !impressions || !ecpm || !exchangeRate || !roiGoal) {
      toast.error("Por favor, preencha todos os campos.");
      return;
    }

    // Cálculos
    const impressionsPerPageView = impressions / sessions;
    const revenuePerImpressionUSD = ecpm / 1000;
    const revenuePerPageViewUSD =
      impressionsPerPageView * revenuePerImpressionUSD;
    const revenuePerPageViewBRL = revenuePerPageViewUSD * exchangeRate;
    const idealCpcBRL = revenuePerPageViewBRL / (1 + roiGoal);

    // Cálculo do ROI Real
    const realROI = (revenuePerPageViewBRL - idealCpcBRL) / idealCpcBRL;

    setResults({
      impressionsPerPageView: impressionsPerPageView.toFixed(4),
      revenuePerImpressionUSD: revenuePerImpressionUSD.toFixed(5),
      revenuePerPageViewUSD: revenuePerPageViewUSD.toFixed(5),
      revenuePerPageViewBRL: revenuePerPageViewBRL.toFixed(4),
      idealCpcBRL: idealCpcBRL.toFixed(4),
    });

    setCalculatedROI(realROI.toFixed(2));
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen max-w-4xl mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 flex items-center">
        <FaCalculator className="mr-2" />
        Cálculo de RPS
      </h2>

      <form
        onSubmit={handleCalculate}
        className="bg-white p-6 rounded-lg shadow-md"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Número de Sessões */}
          <div>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="sessions"
            >
              Número de Sessões (GA4)
            </label>
            <input
              type="number"
              id="sessions"
              value={sessions}
              onChange={(e) => setSessions(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Ex: 1200"
              required
            />
          </div>

          {/* Impressões */}
          <div>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="impressions"
            >
              Impressões (GAM/Adsense)
            </label>
            <input
              type="number"
              id="impressions"
              value={impressions}
              onChange={(e) => setImpressions(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Ex: 2300"
              required
            />
          </div>

          {/* eCPM */}
          <div>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="ecpm"
            >
              eCPM/RPM (USD) (GAM/Adsense)
            </label>
            <input
              type="number"
              id="ecpm"
              step="0.01"
              value={ecpm}
              onChange={(e) => setEcpm(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Ex: 13"
              required
            />
          </div>

          {/* Taxa de Câmbio */}
          <div>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="exchangeRate"
            >
              Taxa de Câmbio (USD para BRL)
            </label>
            <input
              type="number"
              id="exchangeRate"
              step="0.0001"
              value={exchangeRate}
              onChange={(e) => setExchangeRate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Ex: 5.81"
              required
            />
          </div>

          {/* Meta de ROI */}
          <div>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="roiGoal"
            >
              Meta de ROI (%)
            </label>
            <input
              type="number"
              id="roiGoal"
              step="0.1"
              value={roiGoal * 100}
              onChange={(e) => setRoiGoal(e.target.value / 100)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Ex: 50"
              required
            />
          </div>
        </div>

        {/* Botão de Calcular */}
        <button
          type="submit"
          className="mt-6 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Calcular
        </button>
      </form>

      {/* Seção de Resultados */}
      {results && (
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Card: Impressões por Pageview */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold text-gray-700">
              Impressões por Pageview
            </h4>
            <p className="mt-2 text-gray-600">
              {results.impressionsPerPageView}
            </p>
          </div>

          {/* Card: Receita por Impressão (USD) */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold text-gray-700">
              Receita por Impressão (USD)
            </h4>
            <p className="mt-2 text-gray-600">
              ${results.revenuePerImpressionUSD}
            </p>
          </div>

          {/* Card: Receita por Pageview (USD) */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold text-gray-700">
              Receita por Pageview (USD)
            </h4>
            <p className="mt-2 text-gray-600">
              ${results.revenuePerPageViewUSD}
            </p>
          </div>

          {/* Card: Receita por Pageview (BRL) */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold text-gray-700">
              Receita por Pageview (BRL)
            </h4>
            <p className="mt-2 text-gray-600">
              R$ {results.revenuePerPageViewBRL}
            </p>
          </div>

          {/* Card: CPC Ideal (BRL) */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold text-gray-700">
              CPC Ideal (BRL)
            </h4>
            <p className="mt-2 text-gray-600">R$ {results.idealCpcBRL}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RPSCalculation;
